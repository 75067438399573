.footer {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.75);
    font-size: 14px;
    .nav {
    	a {
		    font-size: 14px;
			color: rgba(255, 255, 255, 0.75);
			+ a {
				margin-left: 18px;
			}
    	}
    }
}