// Media Responsive
@mixin up-xsm {
	@media(min-width: $px-media-xsm + 1 ){
		@content;
	}
}

@mixin down-xsm {
	@media(max-width: $px-media-xsm){
		@content;
	}
}

@mixin up-sm {
	@media(min-width: $px-media-sm + 1 ){
		@content;
	}
}

@mixin down-sm {
	@media(max-width: $px-media-sm){
		@content;
	}
}

@mixin up-md {
	@media(min-width: $px-media-md + 1 ){
		@content;
	}
}

@mixin down-md {
	@media(max-width: $px-media-md){
		@content;
	}
}

@mixin up-lg {
	@media(min-width: $px-media-lg + 1 ){
		@content;
	}
}

@mixin down-lg {
	@media(max-width: $px-media-lg){
		@content;
	}
}